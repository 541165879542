import { Heading, Paragraph, Image, Link } from '@loveholidays/design-system';
import React, { Fragment } from 'react';

import { StaticCarouselSection as StaticCarouselSectionType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

export type StaticCarouselSectionProps = ClassNameProps & StaticCarouselSectionType;

export const StaticCarouselSection: React.FC<StaticCarouselSectionProps> = ({
  className,
  title,
  image,
  description,
  link,
}) => {
  const content = (
    <Fragment>
      <div
        sx={{
          marginBottom: '2xs',
        }}
      >
        <Image
          lazy
          src={image.url}
          alt={image.description}
          width={[270, 332, 384]}
          height={240}
          sx={{
            borderRadius: 8,
            overflow: 'hidden',
            maskImage: '-webkit-radial-gradient(white, black)',
            img: {
              transition: ({ motion }) =>
                `transform ${motion.moveElephant.duration}ms ${motion.moveElephant.easing}`,
            },
          }}
        />
      </div>
      <Heading
        variant="small"
        as="h3"
      >
        {title}
      </Heading>
      <Paragraph
        variant="extrasmall"
        sx={{
          color: 'textDimmedheavy',
        }}
      >
        {description}
      </Paragraph>
    </Fragment>
  );

  return link ? (
    <WithClickTracking action="static-carousel-section">
      <Link
        href={link.href}
        asAnchor={link.asAnchor}
        className={className}
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'textDefault',

          '&:hover, &:visited': {
            color: 'textDefault',
          },
          '&:hover': {
            img: {
              transform: 'scale(1.04)',
            },

            h3: {
              textDecoration: 'underline',
            },
          },
        }}
      >
        {content}
      </Link>
    </WithClickTracking>
  ) : (
    <div className={className}>{content}</div>
  );
};
