import { Heading, CarouselRenderProp } from '@loveholidays/design-system';
import React from 'react';

import { StaticCarouselSection } from './StaticCarouselSection';
import { StaticCarouselProps as StaticCarouselPropsType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';

export interface StaticCarouselProps
  extends Omit<ClassNameProps, 'data-id'>,
    StaticCarouselPropsType {}

export const StaticCarousel: React.FC<StaticCarouselProps> = ({
  id,
  title,
  className,
  sections,
}) => (
  <CarouselRenderProp
    render={({ carousel, scrollButtons }) => (
      <section
        sx={{ marginY: ['2xl', null, '3xl'] }}
        id={id}
        className={className}
        data-id="StaticCarousel"
      >
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 's',
            alignItems: 'baseline',
          }}
        >
          <Heading
            variant="largebold"
            as="h2"
          >
            {title}
          </Heading>
          {scrollButtons}
        </div>
        {carousel}
      </section>
    )}
  >
    {sections.map((section, index) => (
      <StaticCarouselSection
        key={index}
        {...section}
      />
    ))}
  </CarouselRenderProp>
);
